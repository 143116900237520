import axios from "axios"

const defaultConfig = {
    baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
    WithCredential: true,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

const DefaultInstanceFile = axios.create(defaultConfig)

const refreshToken = async () => {
    // eslint-disable-next-line no-unused-vars
    const session = JSON.parse(localStorage.getItem('session'));

    try {
        const response = await DefaultInstanceFile.post('/auth/jwt/refresh/', {
            refresh: session.refresh,
        }, {headers: {'Content-Type': 'application/json'}});

        const refreshedSession = response.data;

        if (!refreshedSession.access) {
            localStorage.removeItem('session');
        }

        localStorage.setItem('session', JSON.stringify(refreshedSession));

        return refreshedSession;
    } catch (error) {
        console.log(error)
        localStorage.removeItem('session');
    }
};

DefaultInstanceFile.interceptors.request.use(
    async (config) => {
        const session = JSON.parse(localStorage.getItem('session'))

        if (session) {
            config.headers['Authorization'] = 'Bearer ' + session.access
        }

        return config;
    },
    (error) => Promise.reject(error)
)

DefaultInstanceFile.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error.config
        const session = JSON.parse(localStorage.getItem('session'))

        if (error.response.status === 401 && !config.sent && session) {
            if (config.url.includes('/auth/jwt/refresh/')) {
                localStorage.removeItem('session')
                return Promise.reject(error)
            }

            config.sent = true

            const result = await refreshToken()

            if (result.access) {
                config.headers['Authorization'] = 'Bearer ' + result.access
            }

            return DefaultInstanceFile(config)
        } else if ([400, 404].includes(error.response.status) && error.response.data){
            return error.response
        }
        return Promise.reject(error)
    }
)

export const DefaultApiInstanceFile = DefaultInstanceFile