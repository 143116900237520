<template>
  <PerfectScrollbar id="roadmap">
    <div class="container">
      <img :src="imageUrl" alt="" v-if="imageUrl">
    </div>
  </PerfectScrollbar>
</template>

<script>
export default {
  name: 'roadmap',
  data(){
    return{
      imageUrl: ''
    }
  },
  created(){
    this.$load(async() => {
      const settings = await this.$api.settings.getSettings()
      for(const setting of settings.data){
        if(setting.name === 'Roadmap'){
          const roadmap = await this.$api.settingsImage.getImage(setting.id)
          this.imageUrl = URL.createObjectURL(roadmap.data)
          break
        }
      }
    })
  }
}
</script>

<style scoped>
#roadmap{height: 100%;}
.container{display: flex;justify-content: center;width: 100%;}
img{width: 100%; height: 500px;}
</style>