<template>
  <PerfectScrollbar id="navigation">
    <ul class="section-list">
      <li v-for="(section, sectionIdx) in sections" :key="sectionIdx">
        <router-link :to="section.to">
          <div :class="{'section': true, 'section-active': $route.path.includes(section.to)}">
            <div class="section-container"><i :class="section.class"></i><span>{{section.name}}</span></div>
          </div>
        </router-link>
        <div class="sub-section-container" v-if="$route.path.includes(section.to) && section.subSections.length > 0">
          <ul class="sub-section-list" v-for="(subSection, subSectionIdx) in section.subSections" :key="subSectionIdx">
            <li class="sub-section"><router-link :to="section.to + subSection.to"><span>{{subSection.name}}</span></router-link></li>
          </ul>
        </div>
      </li>
    </ul>
  </PerfectScrollbar>
</template>

<script>
export default {
  name: "navigation",
  data(){
    return {
      sections: [
        {name: 'RoadMap', class: 'fa-regular fa-map', to: '/roadmap', subSections: []},
        {name: 'Messages', class: 'fa-regular fa-message', to: '/messages', subSections: []},
        {name: 'Tasks', class: 'fa-solid fa-list-check', to: '/tasks', subSections: []},
        {name: 'Lectures', class: 'fa-regular fa-clipboard', to: '/lectures', subSections: []},
        {name: 'Contacts', class: 'fa-regular fa-address-book', to: '/contacts', subSections: []},
        {name: 'Email', class: 'fa-regular fa-envelope', to: '/email', subSections: []},
        {name: 'Contract', class: 'fa-regular fa-rectangle-list', to: '/contract', subSections: []}
      ]
    }
  }
}
</script>

<style>

</style>

<style scoped>
#navigation{width: 220px;background-color: #ffffff;height: 100%; border-radius: 10px;}
.section-list{margin: 0; padding: 10px; list-style: none;text-align: left;}
.section{border-radius: 10px;}
.section-active{background-color: #edf0f7;}
.section-container div{display: flex;align-items: center;}
.section-container{margin: 0 5px;padding: 7px 5px;border-bottom: 1px solid rgb(0, 0, 0, 0.1);}
.section-container span{margin-left: 10px;font-weight: 600;}
.section:hover{cursor: pointer; background-color: #edf0f7;}
.sub-section-list{margin: 0; padding-left: 35px; list-style: none;text-align: left;font-size: 13px;}
.sub-section-container{background-color: #ffffff;margin-top: 5px;border-radius: 10px;}
.sub-section{padding: 5px 0;}
.sub-section span{padding: 5px 10px;border-radius: 10px;}
.sub-section span:hover{cursor: pointer; background-color: #edf0f7;}
i {width: 15px;}
</style>