<template>
  <div id="emails">
    <NavEmail :idxEmail="idxEmail" :mailBoxes="emails" :idxBox="idxBox" :newLetters="newLetters"
                @updateIdxEmail="updateIdxEmail" @updateIdxBox="updateIdxBox"/>
    <div class="container">
      <div class="panel">
        <div :class="{'row': true, 'row-active': true}" @click="changeShowModal([], null, 0)" v-if="emails.length > 0">
          <i class="fa-solid fa-pen-to-square"></i><span>Write</span>
        </div>
      </div>
      <div class="container-email">
        <ReadForm :letters="data" :idxBox="idxBox" :idxReadEmail="idxReadEmail" @changeShowModal="changeShowModal"
                     @updateReadLetter="updateReadLetter" @updateIdxReadEmail="updateIdxReadEmail"/>
      </div>
      <ModalLetterEmail :recipients="recipients" :answerId="answerId" :sender="this.emails[this.idxEmail]"
                        @changeShowModal="changeShowModal" @changeRecipients="changeRecipients" v-if="modalLetter.showModal"/>
    </div>
  </div>
</template>

<script>

import ReadForm from "@/components/Home/Email/ReadForm.vue";
import NavEmail from "@/components/Home/Email/NavEmail.vue";
import ModalLetterEmail from "@/components/Home/Email/ModalLetterEmail.vue";
export default {
  name: "emails",
  components: {ModalLetterEmail, ReadForm, NavEmail},
  data(){
    return {
      modalLetter: {showModal: false},
      idxReadEmail: null,
      idxEmail: 0,
      idxBox: null,
      emailForm: false,
      data: [],
      answerId: null,
      emails: [],
      newLetters: 0,
      recipients: []
    }
  },
  created() {
    this.loadMailBoxes()
  },
  methods:{
    loadMailBoxes(){
      this.emails = [this.$store.getters.getUser]
      this.updateIdxEmail(0)
    },
    loadLetters(){
      this.data = []
      this.$load(async() => {
        const letters = await this.$api.email.getLetters(this.idxBox, this.emails[this.idxEmail].id)
        this.data = letters.data
        if (this.idxBox === 1){
          this.changeNewLetters()
        }
      })
    },
    updateIdxReadEmail(emailIndex) {
      this.idxReadEmail = emailIndex
    },
    updateIdxEmail(emailIndex) {
      this.idxEmail = emailIndex
      this.updateIdxBox(1)
    },
    updateIdxBox(boxIndex) {
      this.idxBox = boxIndex
      this.idxReadEmail = null
      this.loadLetters()
    },
    changeShowModal(recipients, answerId, isSend){
      this.modalLetter.showModal = !this.modalLetter.showModal
      if(recipients){
        this.recipients = recipients
        this.answerId = answerId
      }
      if(isSend){
        this.updateIdxBox(1)
      }
    },
    changeRecipients(contact){
      this.recipients = []
      this.recipients.push(contact)
    },
    changeNewLetters() {
      let cntLetters = 0
      for (const letter of this.data){
        cntLetters += letter.new_letters
      }
      this.newLetters = cntLetters
    },
    updateReadLetter(email, letter){
      if (this.idxBox === 1 && !letter.is_read) {
        this.$load(async() => {
          const response = await this.$api.email.updateEmailReadLetter(letter.id)
          if (response.status === 200){
            letter.is_read = true
            email.new_letters -= 1
            this.changeNewLetters()
          }
        })
      }
    }
  }
}
</script>

<style scoped>
#emails{display: flex;height: 100%;font-size: 13px;}
.container{width:740px;margin-left: 9px;height: 100%;}
.panel{display: flex;border-bottom: 1px solid rgb(0,0,0,0.2);height: 30px;}
.panel span{margin-left: 5px;}
.panel div{font-size: 14px;display: flex;align-items: center;height: 30px;padding: 0 10px;}
.container-email{height: calc(100% - 31px);}
.row{color:#999999;}
.row-active{color:#212121;}
.row-active:hover{background-color: #eef0f5;cursor: pointer;}
</style>