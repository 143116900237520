<template>
  <router-view />
</template>

<script>
//import Home from "@/components/Home.vue";

export default {
  name: 'App',
  components: {
    //Home
  }
}
</script>

<style>
#app {
  font-family: Montserrat-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #212121;
  font-size: 14px;
  height: 100%;
  background-color: #e2e6ee;
}
body {
  margin: 0;
  height: 100%;
}
header {
  width: 100%;
}
a {
  text-decoration: none;
  color: #212121;
}
html {
  height: 100%;
}
</style>