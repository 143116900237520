export default {
    state: {
        project: null
    },
    getters: {
        getProject(state){
            return state.project
        }
    },
    mutations: {
        SET_PROJECT(state, payload){
            state.project = payload
        },
        DELETE_PROJECT(state){
            state.project = null
        }
    },
    actions: {
        setProject({ commit }, payload){
            commit('SET_PROJECT', payload)
        },
        deleteProject({ commit }){
            commit('DELETE_PROJECT')
        }
    }
}