export default function (instance){
    return {
        getMessages(dialogId=null){
            const url = dialogId? `/message/view/?dialog=${dialogId}` : `/message/view/`
            return instance.get(url)
        },
        sendMessage(payload){
            return instance.post('/message/', payload)
        },
    }
}