<template>
  <div class="table-header">
    <div class="header-range" v-for="(field, fieldIndex) in detail.fields" :key="fieldIndex">
      <span>{{field.name}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "table-header",
  props: ['detail']
}
</script>

<style scoped>
.table-header{display: flex; background-color: #edf0f7;font-weight: 600;height: 30px;border: 1px solid rgb(0,0,0,0.1);}
.header-range{flex: 1;height: 100%;display: flex;align-items: center;justify-content: center;}
.header-range:not(:last-child){border-right: 1px solid rgb(0,0,0,0.1);}
</style>