<template>
  <div id="messages">
    <div class="container" v-if="dialogId">
      <div class="messages-container">
        <div :class="{'message-container-me': message.sender === dialogId, 'message-container-user': message.sender !== dialogId}"
             v-for="(message, messageIndex) in messages" :key="messageIndex">
          <div :class="{'message':true, 'message-me': message.sender === dialogId , 'message-user': message.sender !== dialogId}">
            <span v-html="message.message"></span>
            <div v-for="(file, fileIndex) in JSON.parse(message.attachments)" :key="fileIndex">
              <i class="fa-solid fa-paperclip"></i><span class="download-file-read" @click="downloadFile(file)">{{ getFileName(file) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="send-message-container">
        <div class="editor-container">
          <quill-editor :options="editorOptions" v-model:content="message" content-type="html"></quill-editor>
        </div>
        <div class="files-panel">
          <input type="file" @change="handleFileAdd($event)" ref="fileInput" style="display: none">
          <button class="btn-add-file" @click="addFile">Add File</button>
          <div class="files">
            <PerfectScrollbar class="files-container">
              <div class="file-container" v-for="(file, fileIndex) in files" :key="fileIndex">
                <span class="download-file">{{ getFileName(file) }}</span><i class="fa-solid fa-xmark remove" @click="deleteFile(fileIndex)"></i>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
      <button class="btn-send" @click="sendMessage({message: message, recipient: dialogId})">Send</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "messages",
  data(){
    return {
      message: '',
      dialogId: null,
      files: [],
      messages: [],
      editorOptions: {
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            //['link', 'image', 'video'],
          ]
        },
        placeholder: 'Enter text...',
        theme: 'snow',
        formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'list', 'indent', 'link', 'image', 'video']
      }
    }
  },
  created(){
    this.getMessages()
  },
  methods:{
    getMessages(){
      this.$load(async() => {
        this.dialogId = this.$store.getters.getUser.id
        const messages = await this.$api.message.getMessages()
        this.messages = messages.data[0].communication
      })
    },
    sendMessage(payload){
      if(this.message.length > 7 || this.files.length > 0){
        this.$load(async() => {
          const formData = new FormData()
          this.files.forEach(item => {
            formData.append('attachments', item)
          })
          formData.append('recipient', payload.recipient)
          formData.append('message', payload.message)
          const response = await(this.$api.messageFormData.sendMessage(formData))
          if(response.status === 201){
            this.getMessages()
            this.message = '<p></p>'
            this.files = []
          }
        })
      }
    },
    getFileName(file){
      if(typeof(file) !== 'string'){
        return file.name
      }
      const arr = file.split(/[/\\]/)
      return arr[arr.length - 1]
    },
    deleteFile(field, fileIndex){
      this.files.splice(fileIndex, 1)
    },
    addFile(){
      this.$refs.fileInput.click()
    },
    handleFileAdd(event){
      if(this.files.length < 10){
        const file = event.target.files[0]
        this.files.push(file)
      }
    },
    downloadFile(path){
      this.$load(async () => {
        const arr = path.split(/[/\\]/)
        const fileName = arr[arr.length - 1]
        const file = await(this.$api.file.getFile({path_file: path}))
        const blob = file.data
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      })
    }
  }
}
</script>

<style scoped>
#messages{display: flex;height: 100%;font-size: 13px;}
.container{width:100%;margin-left: 9px;height: 100%;}
.message{max-width: 50%;padding: 10px 20px;}
.message-container-me{display:flex;justify-content: right;margin: 0 5px;}
.message-container-user{display:flex;justify-content: left;margin: 0 5px;}
.message-container-me:not(:first-child), .message-container-user:not(:first-child){margin-top: 10px;}
.message-me{text-align: right;border-radius: 20px 10px 10px 20px;background-color: #edf0f7;}
.message-user{text-align: left;border-radius: 10px 20px 20px 10px;background-color: #edf0f7;}
.send-message-container{width: 100%;padding: 0;display:flex;margin-top: 20px;height: 200px;}
::-webkit-scrollbar {width: 6px;}
::-webkit-scrollbar-track {cursor:pointer;}
::-webkit-scrollbar-thumb {background-color: #c8cbd0;border-radius: 4px;cursor:pointer;}
.text-send::-webkit-scrollbar-track{margin: 10px 0;}
.messages-container{height: calc(100% - 265px);overflow-y: auto;}
.editor-container{width: 650px;}
.ql-container{height: calc(100% - 42px) !important;}
.download-file-read{cursor: pointer;font-style: italic;color: #003cff;margin-left: 5px;}
.download-file-read:hover{text-decoration: underline;}
.files-panel{flex:1;border: 1px solid rgb(0,0,0,0.2);margin-left: 5px;}
.files-container{height: 100%;}
.btn-add-file{height:40px;border:none;outline: none;background-color: #edf0f7;color: #212121;font-family: Montserrat-Regular, sans-serif;padding: 7px 40px;cursor: pointer; width: 100%;}
.btn-add-file:hover{background-color: #e5eaf6;}
.file-container{height: 30px; padding: 0 10px;display: flex;align-items: center;}
.download-file{margin-right: 10px;}
.remove{cursor: pointer;color: #343434;}
.remove:hover{color: #212121;}
.btn-send{margin-top: 5px;width: 100%; height: 40px;outline: none;background-color: #edf0f7;color: #212121;font-family: Montserrat-Regular, sans-serif;border:none; cursor: pointer;border: 1px solid rgb(0,0,0,0.2);}
.btn-send:hover{background-color: #e5eaf6;}
.files{border-top:1px solid rgb(0,0,0,0.2);height: 158px;}
</style>