export default function (instance){
    return {
        getLectures(payload){
            return instance.post(`/user_course_lecture/lectures/`, payload)
        },
        getQuestions(type, lectureId = null){
            const url = lectureId ? `/task/?type=${type}&lecture=${lectureId}` : `/task/?type=${type}`
            return instance.get(url)
        },
        checkQuestions(payload){
            return instance.post('/task/solutions/1/', payload)
        }
    }
}