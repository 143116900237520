import {DefaultApiInstance} from "./instance"
import {DefaultApiInstanceFile} from "./instanceFile"
import {DefaultApiInstanceImage} from "./instanceImage"

import authModule from './auth'
import userModule from './user'
import projectModule from './project'
import contactModule from './contact'
import taskModule from './task'
import lectureModule from './lecture'
import messageModule from './message'
import dashboardModule from './dashboard'
import uploadModule from './upload'
import settingsModule from './settings'
import settingsImageModule from './settingsImage'
import emailModule from './email'
import fileModule from './file'

export default {
    auth: authModule(DefaultApiInstance),
    user: userModule(DefaultApiInstance),
    project: projectModule(DefaultApiInstance),
    contact: contactModule(DefaultApiInstance),
    task: taskModule(DefaultApiInstance),
    lecture: lectureModule(DefaultApiInstance),
    lectureFormData: lectureModule(DefaultApiInstanceFile),
    message: messageModule(DefaultApiInstance),
    messageFormData: messageModule(DefaultApiInstanceFile),
    file: fileModule(DefaultApiInstanceImage),
    dashboard: dashboardModule(DefaultApiInstance),
    upload: uploadModule(DefaultApiInstanceFile),
    settings: settingsModule(DefaultApiInstanceFile),
    settingsImage: settingsImageModule(DefaultApiInstanceImage),
    email: emailModule(DefaultApiInstance),
    emailFormData: emailModule(DefaultApiInstanceFile)
}