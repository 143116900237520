<template>
  <PerfectScrollbar id="email-form">
    <ul>
      <li class="group" v-for="(email, emailIndex) in letters" :key="emailIndex">
        <div :class="{'email-container': true, 'email-container-active': idxReadEmail === emailIndex, 'email-container-new': email.new_letters > 0,
              'email-container-read': !email.new_letters || email.new_letters === 0}"  @click="updateIdxReadEmail(idxReadEmail === emailIndex? null : emailIndex)">
          <span>{{email.email}}</span><span>{{idxBox? email.template : ''}}</span>
        </div>
        <ul v-if="idxReadEmail === emailIndex">
          <li v-for="(letter, letterIndex) in email.letters" :key="letterIndex">
            <div @click="updatedSelectedLetters(letter.id); updateReadLetter(email, letter)"
                 :class="{'letter': true, 'read': letter.is_read || !idxBox, 'new-letter': !letter.is_read && idxBox}">
              <span>{{letter.theme}}</span><i class="fa-regular fa-envelope" v-if="idxBox && letter.is_answer"></i>
            </div>
            <div class="letter-message-container" v-if="selectedLetters.includes(letter.id)">
              <div class="message" v-html="letter.message"></div>
              <div class="files-container">
                <div class="file-container" v-for="(file, fileIndex) in JSON.parse(letter.attachments)" :key="fileIndex">
                  <i class="fa-solid fa-paperclip"></i><span class="download-file" @click="downloadFile(file)">{{ getFileName(file) }}</span>
                </div>
              </div>
              <div class="reply-container" v-if="idxBox"><button @click="changeShowModal([{id: letter.sender_id, email: email.email}], letter.id, 0)">Reply</button></div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </PerfectScrollbar>
</template>

<script>
export default {
  name: "email-form",
  props: ['letters', 'idxBox', 'idxReadEmail'],
  data(){
    return {
      selectedLetters: [],
      options: {sender: [], recipient: []},
      form: {theme: null, contact: {email: null}, recipient: {email: null}, message: null},
    }
  },
  methods: {
    getFileName(file){
      if(typeof(file) !== 'string'){
        return file.name
      }
      const arr = file.split(/[/\\]/)
      return arr[arr.length - 1]
    },
    updatedSelectedLetters(id){
      const index = this.selectedLetters.indexOf(id)
      index === -1? this.selectedLetters.push(id) : this.selectedLetters.splice(index, 1)
    },
    updateIdxReadEmail(index){
      this.selectedLetters = []
      this.$emit('updateIdxReadEmail', index)
    },
    changeShowModal(recipients, answerId, isSend){
      this.$emit('changeShowModal', recipients, answerId, isSend)
    },
    updateReadLetter(email, letter){
      if(!letter.is_read){
        this.$emit('updateReadLetter', email, letter)
      }
    },
    downloadFile(path){
      this.$load(async () => {
        const arr = path.split(/[/\\]/)
        const fileName = arr[arr.length - 1]
        const file = await(this.$api.file.getFile({path_file: path}))
        const blob = file.data
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      })
    },
  }
}
</script>

<style scoped>
#email-form{height: 100%;text-align: left;}
.letter-message-container{margin-left: 5px;margin-right: 5px;}
.letter{margin-top: 5px; height: 20px;display: flex;align-items: center;cursor: pointer;border-radius: 5px;margin-left: 5px;margin-right: 5px;padding: 0 10px;justify-content: space-between;}
.read{background-color: #ffdd67;}
.new-letter{background-color: #97ec90;}
ul{margin: 0;padding: 0;list-style-type: none;}
.group{padding-top: 10px;}
.email-container{border-radius: 5px;height: 30px;display: flex;align-items: center;padding: 0 10px;cursor: pointer;justify-content: space-between;}
.email-container-read{background-color: #edf0f7;}
.email-container-new{background-color: #97ec90;}
.reply-container{display: flex;justify-content: right;}
.reply-container button {outline: none;border-radius: 10px;background-color: #edf0f7;padding: 3px 20px;border: none;cursor: pointer;font-family: Montserrat-Regular, sans-serif;}
.reply-container button:hover{background-color: #e2e5ee;}
.message{margin-top: 5px;border-radius: 5px;margin-bottom: 5px;}
.download-file{cursor: pointer;font-style: italic;color: #003cff;margin-left:5px;}
.download-file:hover{text-decoration: underline;}
</style>