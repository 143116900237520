export default function (instance){
    return {
        getLetters(typeBox, contactId){
            return instance.get(`/email/list_msgs/${typeBox}/contact/${contactId}/`)
        },
        getRecipients(){
            return instance.get(`/email/recipient/`)
        },
        getContacts(){
            return instance.get(`/email/contacts/`)
        },
        updateEmailReadLetter(id){
            return instance.get(`/email/${id}/read_email/`)
        },
        sendLetter(payload){
            return instance.post(`/email/`, payload)
        },
        updateReadLetter(id){
            return instance.get(`/emailsmtp/${id}/read_email/`)
        }
    }
}