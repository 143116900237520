<template>
  <PerfectScrollbar id="lectures">
    <div class="container">
      <div class="lecture-block"
           v-for="(lecture, lectureIndex) in data" :key="lectureIndex">
        <div class="task-container" :class="{'lecture-container': !lecture.is_completed,
            'complete-container': lecture.is_completed}"
             @click="updateLecture(lecture.id)">
          <span class="lecture-title">{{lecture.name}}</span>
        </div>
        <div class="lecture-content" v-if="lecture.id === lectureId" :key="lectureId">
          <div>
            <div v-html="lecture.content.content"></div>
            <div class="download-container">
              <div class="file-container" v-for="(file, fileIndex) in JSON.parse(lecture.attachments)" :key="fileIndex">
                <i class="fa-solid fa-paperclip"></i><span class="download-file" @click="downloadFile(file)">{{ getFileName(file) }}</span>
              </div>
            </div>
          </div>
          <Questions :lectureId="lecture.id" v-if="!lecture.is_completed" @loadData="loadData"/>
        </div>
      </div>
    </div>
  </PerfectScrollbar>
</template>

<script>

import Questions from "@/components/Home/Questions.vue";

export default {
  name: 'lectures',
  components: {Questions},
  data(){
    return{
      data: [],
      lectureId: null
    }
  },
  created(){
    this.loadData()
  },
  methods:{
    loadData() {
      this.$load(async() => {
        const lectures = await this.$api.lecture.getLectures()
        this.data = lectures.data
      })
    },
    updateLecture(lectureId){
      if(this.lectureId === lectureId){
        this.lectureId = null
      } else {
        this.lectureId = lectureId
      }
    },
    getFileName(file){
      const arr = file.split(/[/\\]/)
      return arr[arr.length - 1]
    },
    downloadFile(path){
      this.$load(async () => {
        const arr = path.split(/[/\\]/)
        const fileName = arr[arr.length - 1]
        const file = await(this.$api.file.getFile({path_file: path}))
        const blob = file.data
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      })
    }
  }
}
</script>

<style scoped>
#lectures{height: 100%;}
.container{width: 100%;}
.lecture-container{background-color: #edf0f7;display: flex;border-radius: 20px;height: 40px;align-items: center;padding: 0 20px;cursor:pointer;}
.lecture-block:not(:first-child){margin-top: 10px;}
.lecture-content{margin-top: 5px;}
.complete-container{background-color: #97ec90;;display: flex;border-radius: 20px;height: 40px;align-items: center;padding: 0 20px;cursor:pointer;}
.download-file{cursor: pointer;font-style: italic;color: #003cff;margin-left:5px;}
.download-file:hover{text-decoration: underline;}
.file-container{text-align: left;}
.download-container{padding: 20px;}
</style>