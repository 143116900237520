export default function (instance){
    return {
        getUser(){
            return instance.get('/user/profile/')
        },
        createUser(payload){
            return instance.post('/user/', payload)
        },
        passwordRecovery(payload){
            return instance.post('/user/password_recovery/', payload)
        }
    }
}