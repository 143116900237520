<template>
  <div id="lms-form">
    <div class="container-lms-form">
      <div class="lms-form">
        <span class="lms-form-title">
          LMS System
        </span>
        <div class="info" v-if="isCreated">
          <div><span class="text-info">Thank you for your interest!</span></div>
          <div><span class="text-info">Our HR department will get in touch with you soon.</span></div>
        </div>
        <div class="block" v-if="!isCreated">
          <div class="wrap-input" v-for="(field, fieldIndex) in fields" :key="fieldIndex">
            <input v-model="form[field.name]" :type="field.type" :placeholder="field.title" class="input" />
            <span class="focus-input"></span>
            <span class="symbol-input">
              <i :class="{ 'fa': true, [field.class]: true, 'mr-5': true }"></i>
            </span>
          </div>

          <div v-if="formErrors.length > 0" class="alert-container">
            <div v-for="(error, errorIndex) in formErrors" :key="errorIndex">
              <i class="fa fa-circle"></i>
              {{ error }}
            </div>
          </div>

          <div class="container-btn">
            <button type="submit" class="lms-btn" @click="signIn">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "lms-form",
  data() {
    return {
      form: {
      },
      fields: [
        {name: 'email_personal', title: 'Email', type: 'email', class: 'fa-envelope'},
        {name: 'first_name', title: 'First Name', type: 'text', class: 'fa-bars'},
        {name: 'last_name', title: 'Last Name', type: 'text', class: 'fa-bars'},
        {name: 'phone_number', title: 'Phone', type: 'text', class: 'fa-phone'},
        {name: 'address', title: 'Address', type: 'text', class: 'fa-location-dot'},
        {name: 'city', title: 'City', type: 'text', class: 'fa-building'},
        {name: 'state', title: 'State', type: 'text', class: 'fa-globe'},
        {name: 'zip_val', title: 'Zip', type: 'number', class: 'fa-bars'}
      ],
      formErrors: [],
      isCreated: false
    }
  },
  methods: {
    signIn() {
      this.$load(async() => {
        const response = await this.$api.user.createUser(this.form)
        const data = response.data
        if(response.status === 400){
          this.formErrors = []
          Object.keys(data).forEach(key => {
            this.fields.forEach(item => {
              if(item.name === key) {
                this.formErrors.push(`${item.title} - ${data[key][0]}`)
              }
            })
          })
        }
        if(response.status === 201) {
          this.formErrors = []
          this.isCreated = true
        }
      })
    },
  }
}
</script>

<style>

</style>

<style scoped>
#lms-form{width:100%;margin:0 auto; height: 100%;background-color: #f2f2f2;}
.container-lms-form{height:100%;width:100%;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;padding:0;background-repeat:no-repeat;background-size:cover;background-position:center;}
.lms-form-title{margin-bottom:30px;margin-top:20px;font-family:Montserrat-ExtraBold, sans-serif;font-size:24px;color:#ffffff;line-height:1.2;text-align:center;width:100%;display:block}
.lms-form{background-color:#212121;border-radius: 20px;padding: 20px; border: 1px solid rgb(0, 0, 0);width:450px;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;justify-content:space-between;flex-wrap:wrap}
.wrap-input{position:relative;width:100%;z-index:1;margin-bottom:15px;}
.input{width: calc(100% - 83px); font-family:Montserrat-Bold, sans-serif;font-size:15px;line-height:1.2;color:#333;display:block;background:#fff;height:50px;border-radius:25px;padding:0 30px 0 53px;border:1px solid rgb(0,0,0,0.2)}
.focus-input{display:block;position:absolute;border-radius:25px;bottom:0;left:0;z-index:-1;width:100%;height:100%;box-shadow:0 0;color:rgba(0,91,234,.6)}
.input:focus+.focus-input{-webkit-animation:anim-shadow .5s ease-in-out forwards;animation:anim-shadow .5s ease-in-out forwards}@-webkit-keyframes anim-shadow{to{box-shadow:0 0 80px 30px;opacity:0}}@keyframes anim-shadow{to{box-shadow:0 0 80px 30px;opacity:0}}
.symbol-input{font-size:15px;color:#999;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;align-items:center;position:absolute;border-radius:25px;bottom:0;left:0;width:100%;height:100%;padding-left:25px;pointer-events:none;-webkit-transition:all .4s;-o-transition:all .4s;-moz-transition:all .4s;transition:all .4s}
.input:focus+.focus-input+.symbol-input{color:#00c6fb;padding-left:23px}
.container-btn{width:100%;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;flex-wrap:wrap;justify-content:center}
.lms-btn{margin-top:10px;border:none;font-family:Montserrat-Bold, sans-serif;font-size:15px;line-height:1.5;color:#e0e0e0;width:100%;height:50px;border-radius:25px;background:#333;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;justify-content:center;align-items:center;padding:0 25px;-webkit-transition:all .4s;-o-transition:all .4s;-moz-transition:all .4s;transition:all .4s;position:relative;z-index:1}
.lms-btn::before{content:"";display:block;position:absolute;z-index:-1;width:100%;height:100%;border-radius:25px;top:0;left:0;background:#005bea;background:-webkit-linear-gradient(to left ,#005bea,#00c6fb);background:-o-linear-gradient(to left,#005bea,#00c6fb);background:-moz-linear-gradient(to left,#005bea,#00c6fb);background:linear-gradient(to left,#005bea,#00c6fb);-webkit-transition:all .4s;-o-transition:all .4s;-moz-transition:all .4s;transition:all .4s;opacity:0}
.lms-btn:hover{background:0 0;color:#fff;cursor: pointer;}
.lms-btn:hover:before{opacity:1}
:active,:hover,:focus {outline:0;outline-offset: 0;}
.alert-container{color: #ffffff;width: 100%;margin-left: 20px;margin-bottom: 15px;font-size: 12px;display: inline-block;font-family:Montserrat-Regular, sans-serif;text-align: left;}
.fa-circle{font-size: 5px;vertical-align: middle;}
.block{width: 100%;}
.text-info{color: #ffffff;}
.info{width: 100%;padding-bottom: 15px;}
</style>