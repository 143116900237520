<template>
  <PerfectScrollbar id="contract">
    <div class="contract-container">
      <div class="content-container">
        <div ref="contract" class="contract">
          <div class="download-container" v-if="signature.contract">
            <i class="fa-solid fa-download download" title="Download" @click="downloadFile(signature.contract)"></i>
          </div>
          <h2 class="title">SERVICE CONTRACT</h2>
          <div class="text-block" v-if="data" v-html="data.contract.text"></div>
          <div class="signature-form">
            <div class="signature-container">
              <span>SIGNATURE:</span>
              <div class="signature-block"><span :class="{'signature-active': true}">{{signature.text}}</span></div>
            </div>
            <div v-if="!signature.is_signature && data">
              <input class="input-signature-form" v-model="signature.text">
            </div>
          </div>
        </div>
        <div v-if="!signature.is_signature && data">
          <ul class="list-uploads">
            <li><div class="uploads-container"><span>{{data.contract.upload_1}}:</span><input type="file" @change="handleFileUpload('1', $event)"></div></li>
            <li><div class="uploads-container"><span>{{data.contract.upload_2}}:</span><input type="file" @change="handleFileUpload('2', $event)"></div></li>
            <li><div class="uploads-container"><span>{{data.contract.upload_3}}:</span><input type="file" @change="handleFileUpload('3', $event)"></div></li>
          </ul>
          <div class="error" v-if="error.length > 0"><span>{{error}}</span></div>
          <button @click="submit">Submit</button>
        </div>
      </div>
    </div>
  </PerfectScrollbar>
</template>

<script>
import html2pdf from 'html2pdf.js'
export default {
  name: 'contract',
  data(){
    return{
      files: {},
      error: '',
      data: null,
      signature : {is_signature: false, text: ''}
    }
  },
  created(){
    this.$load(async() => {
      const response = await this.$api.project.getProjects()
      this.data = response.data.length > 0? response.data[0] : []
      const user = this.$store.getters.getUser
      const signature = user.signature
      this.signature = signature? signature : this.signature
    })
  },
  methods:{
    async generatePDF() {
      const element = this.$refs.contract
      const opt = {
        margin: 1,
        filename: 'contract.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      const blob = await html2pdf().from(element).set(opt).output('blob')
      return new File([blob], 'document.pdf', { type: 'application/pdf' })
    },
    handleFileUpload(key, event) {
      if(key in this.files){
        delete this.files[key]
      } else {
        this.files[key] = event.target.files[0]
      }
    },
    downloadFile(path){
      this.$load(async () => {
        const arr = path.split(/[/\\]/)
        const fileName = arr[arr.length - 1]
        const file = await(this.$api.file.getFile({path_file: path}))
        const blob = file.data
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      })
    },
    async submit(){
      if(this.signature.text.length === 0) {
        this.error = 'Signature required'
      } else if(this.data.contract.is_required_1 && !('1' in this.files)){
        this.error = `${this.data.contract.upload_1} required`
      } else if(this.data.contract.is_required_2 && !('2' in this.files)){
        this.error = `${this.data.contract.upload_2} required`
      } else if(this.data.contract.is_required_3 && !('3' in this.files)){
        this.error = `${this.data.contract.upload_3} required`
      } else {
        this.$load(async() => {
          this.error = ''
          this.signature.is_signature = true
          const formData = new FormData()
          Object.keys(this.files).forEach(key => {
            formData.append('files', this.files[key])
          })
          const pdfFile = await this.generatePDF();
          formData.append('contract', pdfFile)
          formData.append('signature', JSON.stringify(this.signature))
          const contract = await this.$api.upload.saveSignature(formData)
          if(contract.status === 201){
            this.$router.push({name: 'roadmap'})
          } else {
            this.signature.is_signature = false
          }
        })
      }
    }
  }
}
</script>

<style scoped>
#contract{height: 100%;}
.contract-container{width: 600px;margin: 0 auto;padding-top: 20px;}
.title{font-family: Montserrat-ExtraBold, serif;font-size: 15px;text-align: center;width: 100%;}
.text-block {text-align: left;margin-top: 30px;}
ul{list-style-type: none;padding: 0;margin: 0;}
button{margin-top: 30px;padding: 7px 20px;border-radius: 20px;outline: none;background-color: #edf0f7;border: none;cursor:pointer;font-family: Montserrat-Regular, sans-serif;}
button:hover{background-color: #e1e5ef;}
i{margin-right: 10px;font-size: 12px;}
.list-text li div{padding: 2px 0;}
.signature-container{text-align: left;display: flex;align-items: center;width: 300px;}
.list-uploads{margin-top: 30px;}
.signature-block{margin-left: 30px;border-bottom: 1px solid rgb(0,0,0,0.1);padding: 5px 10px;height: 15px;}
.signature-active{color: #212121;font-family: 'Lucida Handwriting', sans-serif;}
.uploads-container{margin-top: 5px;display: flex;align-items: center;}
.uploads-container span{width: 100px;}
.error{color: #f62e2e;font-size: 11px;margin-top: 30px;}
.signature-form{display:flex;align-items: center;margin-top: 30px;}
.input-signature-form{margin-left: 30px;height: 30px; padding: 0 10px;border-radius: 10px;outline: none;border:1px solid rgb(0,0,0,0.2);font-family: Montserrat-Regular, sans-serif;}
.download-container{display: flex;}
.download{font-size: 20px;cursor: pointer;color: #7c7c7c;}
.download:hover{color: #545454;}
</style>

<style>
.ql-font-lucidahandwriting {font-family: 'Lucida Handwriting', sans-serif;}
</style>