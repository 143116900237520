<template>
  <div>
    <div class="modal-overlay" @click="changeShowModal([], null, 0)"></div>
    <div class="modal">
      <div class="modal-content">
        <div class="container-close"><i class="fa-solid fa-xmark close-btn" @click="changeShowModal([], null, 0)"></i></div>
        <div class="modal-header">
          <span class="title">Send</span>
        </div>
        <div class="modal-body" v-if="isLoaded">
          <div class="body-content">
            <div class="main-container">
              <div class="nav-container">
                <div class="panel-container">
                  <div class="row-panel">
                    <span class="name-panel">From</span>
                    <input v-model="from.email" class="input-panel" readonly>
                  </div>
                  <div class="row-panel">
                    <span class="name-panel">Subject</span><input v-model="subject" class="input-panel">
                  </div>
                  <div class="row-buttons">
                    <div class="button-container"><button @click="send">Send</button></div>
                  </div>
                </div>
                <div class="files-panel">
                  <input type="file" @change="handleFileAdd($event)" ref="fileInput" style="display: none">
                  <button class="btn-add-file" @click="addFile">Add File</button>
                  <PerfectScrollbar class="files-container">
                    <div class="file-container" v-for="(file, fileIndex) in files" :key="fileIndex">
                      <span class="download-file">{{ getFileName(file) }}</span><i class="fa-solid fa-xmark remove" @click="deleteFile(fileIndex)"></i>
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
              <div class="text-container">
                <quill-editor :options="editorOptions" v-model:content="message" content-type="html"></quill-editor>
              </div>
            </div>
            <div class="recipients-container">
              <div class="recipients-title-container"><span class="recipients-title">Recipients</span></div>
              <PerfectScrollbar class="recipients-body">
                <div v-if="isRecipients">
                  <div class="recipient-container" v-for="(recipient, recipientIndex) in recipients" :key="recipientIndex">
                    <span>{{recipient.email}}</span>
                  </div>
                </div>
                <select v-model="recipient" @change="changeRecipients" v-else>
                  <option selected disabled></option>
                  <option v-for="option in options" :key="option.id" :value="option">{{option.email}}</option>
                </select>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['recipients', 'answerId', 'sender'],
  data() {
    return {
      recipient: null,
      from: null,
      isRecipients: false,
      subject: '',
      files: [],
      message: '',
      data: [],
      isLoaded: false,
      editorOptions: {
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link', 'image', 'video'],
          ]
        },
        placeholder: 'Enter text...',
        theme: 'snow',
        formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'list', 'indent', 'link', 'image', 'video']
      }
    };
  },
  created(){
    this.isLoaded = false
    if(this.recipients.length === 0){
      this.$load(async() => {
        const contacts = await this.$api.contact.getContacts()
        this.options = contacts.data
        this.isLoaded = true
      })
    }
    this.from = this.sender
    if(this.recipients.length > 0) {
      this.isRecipients = true
      this.isLoaded = true
    }
  },
  methods: {
    changeShowModal(recipients, answerId, isSend){
      this.$emit('changeShowModal', recipients, answerId, isSend)
    },
    changeRecipients(){
      if(this.recipient) this.$emit('changeRecipients', this.recipient)
    },
    addFile(){
      this.$refs.fileInput.click()
    },
    handleFileAdd(event){
      if(this.files.length < 10){
        const file = event.target.files[0]
        this.files.push(file)
      }
    },
    getFileName(file){
      if(typeof(file) !== 'string'){
        return file.name
      }
      const arr = file.split(/[/\\]/)
      return arr[arr.length - 1]
    },
    async downloadFile(path){
      const arr = path.split(/[/\\]/)
      const fileName = arr[arr.length - 1]
      const file = await(this.$api.file.getFile({path_file: path}))
      const blob = file.data
      return new File([blob], fileName, { type: blob.type, lastModified: Date.now()})
    },
    deleteFile(field, fileIndex){
      this.files.splice(fileIndex, 1)
    },
    async sendEmail(form){
      const formData = new FormData()
      Object.keys(form).forEach(key => {
        if(Array.isArray(form[key])){
          form[key].forEach(value => {
            formData.append(key, value)
          })
        } else {
          formData.append(key, form[key])
        }
      })
      return await this.$api.emailFormData.sendLetter(formData)
    },
    async loadFiles(files) {
      const filePromises = files.map(async file => {
        if (typeof file === 'string') {
          return await this.downloadFile(file)
        } else {
          return file
        }
      });
      return await Promise.all(filePromises);
    },
    send(){
      if(this.recipients.length > 0){
        this.$load(async () => {
          const form = {
            contact_id: this.recipients[0].id,
            theme: this.subject,
            message: this.message,
            attachments: []
          }
          if (this.answerId) form.id_inbox = this.answerId

          const files = await this.loadFiles(this.files)
          form.attachments.push(...files)

          const emailResponse = await this.sendEmail(form)
          if (emailResponse.status === 201) {
            this.changeShowModal([], null, 1)
          }
        })
      }
    }
  },
};
</script>

<style scoped>
.title{font-family: Montserrat-ExtraBold, sans-serif;font-size: 14px;}
.modal-overlay {position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.2);}
.modal {position: fixed;top: 400px;left: 50%;transform: translate(-50%, -50%);background-color: #e2e6ee;padding: 1px;z-index: 1;border-radius: 10px;}
.modal-content {display: flex;flex-direction: column;width: 1000px;height: 600px;background-color: #ffffff;padding: 5px;border-radius: 10px;}
.container-close{position: absolute;top: 7px;right: 12px;border-radius: 10px;display: flex;align-items: center;justify-content: center;}
.close-btn {cursor: pointer;font-size: 20px;color: #dcdcdc;font-weight: 600;}
.close-btn:hover{color: #212121;}
.modal-header {border-bottom: 1px solid rgb(0,0,0,0.2);height: 20px;display:flex;justify-content: space-between;}
.modal-body {padding: 0;margin-top:5px;height: calc(100% - 16px);}
.body-content{display: flex;height: 100%;}
.recipients-container{border-left: 1px solid rgb(0,0,0,0.2); height: 100%; width: 200px;padding-left: 5px;}
.main-container{flex:1;margin-right: 5px;display: flex;flex-direction: column;}
.panel-container{flex:1;}
.text-container{width: 790px;height: 387px;margin-top: 5px;}
.row-panel{display: flex;align-items: center;}
.row-panel:not(:first-child){margin-top: 5px;}
.name-panel{width: 80px;text-align: left;}
.input-panel{height: 18px; font-size: 13px; border-radius: 10px; flex: 1;border: 1px solid rgb(0,0,0,0.2);padding: 3px 10px;font-family: Montserrat-Regular, sans-serif;outline: none;}
.select-panel{height: 26px; font-size: 13px; border-radius: 10px; flex:1;border: 1px solid rgb(0,0,0,0.2);padding: 3px 10px;font-family: Montserrat-Regular, sans-serif;outline: none;cursor: pointer;}
.row-checkbox{display: flex;margin-top: 10px;align-items: center;}
.checkbox-panel{margin-left: 20px;cursor: pointer;}
.checkbox-container{display: flex;align-items: center;}
.checkbox-container:not(:first-child){margin-left: 50px;}
.recipients-title-container{display: flex;align-items: center;height: 20px;border-bottom: 1px solid rgb(0,0,0,0.2);justify-content: center;}
.recipients-body{height: calc(100% - 20px);}
.recipient-container{display: flex; justify-content: left; align-items: center;}
.recipient-container span {background-color: #edf0f7;padding: 5px 10px;border-radius: 10px;margin-top: 5px;}
.row-buttons{margin-top: 10px;display: flex;}
.button-container:not(:first-child){margin-left: 30px;}
.button-container button{border: none;outline: none;padding: 5px 20px;background-color: #edf0f7;cursor: pointer;border-radius: 20px;font-family: Montserrat-Regular, sans-serif;}
.button-container button:hover{background-color: #e6e9f1;}
.nav-container{display: flex;}
.files-panel{width: 300px;height: 182px;border: 1px solid rgb(0,0,0,0.2);margin-left: 5px;}
.btn-test{width: 50px; padding: 5px 10px; background-color: #edf0f7; border-radius: 20px;margin-left: 5px;cursor: pointer;}
.btn-test:hover{background-color: #e6e9f1;}
.files-container{border-top:1px solid rgb(0,0,0,0.2);height: 150px;}
.btn-add-file{border:none;outline: none;background-color: #edf0f7;color: #212121;font-family: Montserrat-Regular, sans-serif;padding: 7px 40px;cursor: pointer; width: 100%;}
.btn-add-file:hover{background-color: #e5eaf6;}
.file-container{height: 30px; padding: 0 10px;display: flex;align-items: center;}
.download-file{margin-right: 10px;}
.remove{cursor: pointer;color: #343434;}
.remove:hover{color: #212121;}
select {width: 100%;padding: 2px;font-family: Montserrat-Regular, sans-serif;outline: none;border: 1px solid rgb(0,0,0,0.2);margin-top: 10px;}
</style>

<style>
.ql-container{height: calc(100% - 42px) !important;}
</style>