<template>
  <PerfectScrollbar id="navEmails">
    <ul class="emails-list">
      <li v-for="(email, emailIndex) in mailBoxes" :key="emailIndex">
        <div :class="{'email': true, 'email-active': idxEmail === emailIndex}" @click="updateIdxEmail(emailIndex)"><span>{{email.email}}</span></div>
        <div class="box-panel" v-if="idxEmail === emailIndex">
          <div class="box-container"><span @click="updateIdxBox(1)" :class="{'box-active': idxBox === 1, 'box': true}">Inbox ({{newLetters}} new mails)</span></div>
          <div class="box-container"><span @click="updateIdxBox(0)" :class="{'box-active': idxBox === 0, 'box': true}">Outbox</span></div>
        </div>
      </li>
    </ul>
  </PerfectScrollbar>
</template>

<script>

export default {
  name: "navEmails",
  props: ['idxEmail', 'mailBoxes', 'idxBox', 'newLetters'],
  methods:{
    updateIdxEmail(emailIndex){
      this.$emit('updateIdxEmail', emailIndex);
    },
    updateIdxBox(boxIndex){
      this.$emit('updateIdxBox', boxIndex);
    },
  }
}
</script>

<style scoped>
#navEmails{width: 200px;padding: 0;height:100%; border-right: 1px solid rgb(0,0,0,0.1);font-size: 13px;}
.emails-list{height: 100%; margin: 0; padding: 0 10px 0 0; list-style-type: none;text-align: left;}
.email{padding: 8px 10px;cursor: pointer;border-bottom: 1px solid rgb(0,0,0,0.1);}
.email:hover{background-color: #edf0f7;}
.email-active{background-color: #edf0f7;font-weight: 600;}
.box-panel{border-bottom: 1px solid rgb(0,0,0,0.1);}
.box-container{height: 25px;display: flex;align-items: center;}
.box{padding: 2px 0;margin-left: 20px;}
.box:hover{cursor: pointer;text-decoration: underline;}
.box-active{font-weight: 600;text-decoration: underline;}
</style>