import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/style.css'

import ApiPlugin from './plugins/api'
import LoadPlugin from './plugins/load'

import { QuillEditor, Quill } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

const Font = Quill.import('formats/font')
Font.whitelist = ['montserrat', 'lucidahandwriting']
Quill.register(Font, true)

createApp(App)
    .use(store)
    .use(router)
    .use(ApiPlugin)
    .use(LoadPlugin)
    .use(PerfectScrollbarPlugin)
    .component('QuillEditor', QuillEditor)
    .mount('#app')