<template>
  <PerfectScrollbar id="tasks">
    <div class="container">
      <div class="task-block"
           v-for="(task, taskIndex) in data" :key="taskIndex">
        <div class="task-container" :class="{'task-container': !task.is_completed,
            'complete-container': task.is_completed}"
             @click="updateTask(task.id)">
          <span class="task-title">{{task.name}}</span>
        </div>
        <div class="task-content" v-if="task.id === taskId" :key="taskId">
          <div>
            <div class="content" v-html="task.text.description"></div>
            <div class="file-container" v-for="(file, fileIndex) in JSON.parse(task.attachments)" :key="fileIndex">
              <i class="fa-solid fa-paperclip"></i><span class="download-file" @click="downloadFile(file)">{{ getFileName(file) }}</span>
            </div>
          </div>
          <button class="btn-complete" v-if="!task.is_completed" @click="completeTask(task.id)">Complete</button>
        </div>
      </div>
    </div>
  </PerfectScrollbar>
</template>

<script>

export default {
  name: 'tasks',
  data(){
    return{
      projectId: null,
      data: [],
      taskId: null
    }
  },
  created(){
    this.loadTasks()
  },
  methods:{
    loadTasks(){
      this.$load(async() => {
        const tasks = await this.$api.task.getTasks()
        this.data = tasks.data
      })
    },
    updateTask(taskId){
      if(this.taskId === taskId){
        this.taskId = null
      } else {
        this.taskId = taskId
      }
    },
    completeTask(taskId){
      this.$load(async() => {
        const response = await this.$api.task.addCompletedTask({task: taskId, answer: 0})
        if(response.status === 201){
          this.loadTasks(this.projectId)
        }
      })
    },
    getFileName(file){
      const arr = file.split(/[/\\]/)
      return arr[arr.length - 1]
    },
    downloadFile(path){
      this.$load(async () => {
        const arr = path.split(/[/\\]/)
        const fileName = arr[arr.length - 1]
        const file = await(this.$api.file.getFile({path_file: path}))
        const blob = file.data
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      })
    }
  }
}
</script>

<style scoped>
#tasks{height: 100%;}
.container{width: 100%;}
.task-container{background-color: #edf0f7;display: flex;border-radius: 20px;height: 40px;align-items: center;padding: 0 20px;cursor:pointer;}
.complete-container{background-color: #97ec90;;display: flex;border-radius: 20px;height: 40px;align-items: center;padding: 0 20px;cursor:pointer;}
.task-block:not(:first-child){margin-top: 10px;}
.task-content{padding: 8px 20px 0 20px;}
.content{text-align: left;}
.btn-complete{margin-top: 10px;padding: 7px 20px;border-radius: 20px;outline: none;background-color: #edf0f7;border: none;cursor:pointer;font-family: Montserrat-Regular, sans-serif;}
.btn-complete:hover{background-color: #e1e5ef;}
.download-file{cursor: pointer;font-style: italic;color: #003cff;margin-left: 5px;}
.download-file:hover{text-decoration: underline;}
.file-container{display: flex;text-align: left;padding: 20px 0;}
</style>