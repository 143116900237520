<template>
  <div id="contacts">
    <div class="container">
      <div class="table">
        <TableHeader :detail="{fields: fields}"/>
        <PerfectScrollbar class="table-body">
          <div class="row" v-for="(contact, contactIndex) in data" :key="contactIndex">
            <div class="range" v-for="(field, fieldIndex) in fields" :key="fieldIndex">
              <span>{{contact[field.form]}}</span>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  </div>
</template>

<script>

import TableHeader from "@/components/Forms/TableHeader.vue";

export default {
  name: 'contacts',
  components: {TableHeader},
  data(){
    return{
      fields: [{name: 'First Name', form: 'first_name'}, {name: 'Last Name', form: 'last_name'},
        {name: 'Phone', form: 'phone_number'}, {name: 'Email', form: 'email'}, {name: 'Comment', form: 'additional'}],
      data: []
    }
  },
  created(){
    this.loadData()
  },
  methods:{
    loadData() {
      this.$load(async() => {
        const contacts = await this.$api.contact.getContacts()
        this.data = contacts.data
      })
    }
  }
}
</script>

<style scoped>
#contacts{height: 100%;display: flex;}
.container{width: 100%;}
.row{display: flex;border-bottom: 1px solid rgb(0,0,0,0.1);height: 30px;}
.range{flex: 1;display: flex;align-items: center;justify-content: center;border-right: 1px solid rgb(0,0,0,0.1);}
.range:first-child{border-left: 1px solid rgb(0,0,0,0.1);}
.table{display: flex;flex-direction: column;}
.table-body{flex:1;}
</style>