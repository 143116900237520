import { createWebHistory, createRouter } from 'vue-router'
import store from "@/store";
import api from "@/api";
import Login from '@/views/Login'
import Home from "@/views/Home.vue";
import RoadMap from "@/views/RoadMap.vue";
import Messages from "@/views/Messages.vue";
import Tasks from "@/views/Tasks.vue";
import Lectures from "@/views/Lectures.vue";
import Contacts from "@/views/Contacts.vue";
import Email from "@/views/Email.vue";
import Contract from "@/views/Contract.vue";
import Form from "@/views/Form.vue";
import PasswordRecovery from "@/views/PasswordRecovery.vue";


const routes = [
    {path: '/', component: Home,  name: 'home', children: [
            { path: 'roadmap', component: RoadMap, name: 'roadmap'},
            { path: 'messages', component: Messages, name: 'messages'},
            { path: 'tasks', component: Tasks, name: 'tasks'},
            { path: 'lectures', component: Lectures, name: 'lectures', children: [
                    {path: 'lectures', component: Lectures, name: 'lectures'}
                ]},
            { path: 'contacts', component: Contacts, name: 'contacts'},
            { path: 'email', component: Email, name: 'email'},
            { path: 'contract', component: Contract, name: 'contract'}
        ]},
    { path: '/login', component: Login,  name: 'login'},
    { path: '/form', component: Form,  name: 'form'},
    { path: '/password_recovery', component: PasswordRecovery,  name: 'password'},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    try {
        const storedUser = await api.user.getUser()
        await store.dispatch('setUser', storedUser.data)
        if(to.name === 'login' || to.name === 'form' || to.name === 'password') return next({name: 'roadmap'})
        if (!storedUser.data.signature && to.name !== 'contract') return next({name: 'contract'})
        next()
    } catch (error) {
        to.name === 'login' || to.name === 'form' || to.name === 'password'? next() : next({name: 'login'})
    }
});

export default router;