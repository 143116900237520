<template>
  <Header />
  <div id="home">
    <Navigation />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Navigation from "@/components/Home/Navigation.vue";

export default {
  name: 'home',
  components: {Header, Navigation}
}
</script>

<style scoped>
#home{width: 1200px;margin: 0 auto;display: flex;height: calc(100% - 60px);}
.content{width: 950px;background-color: #ffffff;height: calc(100% - 20px); border-radius: 10px; margin-left: 10px;padding: 10px;font-size: 13px;}
</style>