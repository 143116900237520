<template>
  <div id="questions">
    <div :class="{'content-container': true, 'container-disabled': isDisabled}">
      <div class="question-container" v-for="(question, questionIndex) in data" :key="questionIndex">
        <div class="question-title"><span class="question">{{question.text.text}}</span></div>
        <div class="answers-container">
          <div class="answer-container" v-for="(answer, answerId) in question.text.answers" :key="answerId" @click="addAnswer(question.id, Number(answerId))">
            <i :class="{'fa-regular' : true, 'fa-circle-check': isAnswerSelected(question.id, Number(answerId)),
              'fa-circle': !isAnswerSelected(question.id, Number(answerId))}"></i>
            <span class="answer">{{ answer.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="error" v-if="errorMessage"><span>{{errorMessage}}</span></div>
    <button @click="checkAnswers" v-if="!isDisabled">Check</button>
    <button @click="loadQuestions" v-else>Retry</button>
  </div>
</template>

<script>

export default {
  name: 'questions',
  props: ['lectureId'],
  data(){
    return{
      data: [],
      selectedAnswers: [],
      isDisabled: false,
      errorMessage: null
    }
  },
  created(){
    this.loadQuestions();
  },
  methods:{
    loadQuestions(){
      this.$load(async() => {
        let questions = await this.$api.lecture.getQuestions('question', this.lectureId)
        this.data = questions.data
        this.selectedAnswers = []
        this.errorMessage = null
        this.isDisabled = false
      })
    },
    isAnswerSelected(taskId, answerId) {
      return this.selectedAnswers.some(selected => selected.answer === answerId && selected.task === taskId)
    },
    addAnswer(taskId, answerId){
      const taskIndex = this.selectedAnswers.findIndex(answer => answer.task === taskId)
      if(taskIndex !== -1){
        this.selectedAnswers[taskIndex] = { task: taskId, answer: answerId }
      } else {
        this.selectedAnswers.push({ task: taskId, answer: answerId })
      }
    },
    checkAnswers(){
      this.$load(async() => {
        const response = await this.$api.lecture.checkQuestions({lecture: this.lectureId, solutions: this.selectedAnswers})
        if(response.status === 201){
          this.$emit('loadData');
        } else {
          this.isDisabled = true
          this.errorMessage = response.data.message
        }
      })
    }
  }
}
</script>

<style scoped>
#questions{padding: 0 20px;}
.answer-container{display:flex;align-items: center;cursor:pointer;padding: 2px 25px;}
.answers-container{margin: 5px 0;}
.answer{margin-left: 10px;padding-bottom: 2px;}
.question-container{text-align: left;}
.question{padding: 0 15px;}
.question-title{display: flex;font-weight: 600;background-color: #edf0f7;width: 100%;height: 25px;align-items: center;border-radius: 20px;}
.fa-regular{font-size: 12px;}
button{margin-top: 10px;padding: 7px 20px;border-radius: 20px;outline: none;background-color: #edf0f7;border: none;cursor:pointer;font-family: Montserrat-Regular, sans-serif;}
button:hover{background-color: #e1e5ef;}
.container-disabled {pointer-events: none;opacity: 0.5;}
.error{color: #f62e2e;font-size: 11px;}
</style>