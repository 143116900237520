<template>
  <header>
    <div class="container" id="lmsHeader">
      <router-link to="/">
        <span class="title-1">LMS</span> <span class="title-2">System</span>
      </router-link>
      <div class="block">
        <div class="block-user" :key="user">
          <i class="fa-regular fa-user"></i>
          <span>{{user.first_name}} {{user.last_name}}</span>
        </div>
        <i class="fa-solid fa-arrow-right-from-bracket" title="Logout" @click="logout"></i>
      </div>
    </div>
  </header>
</template>

<script>
import { toRaw } from 'vue';
export default {
  name: 'lmsHeader',
  components: {},
  data() {
    return {
      user: toRaw(this.$store.getters.getUser)
    }
  },
  methods: {
    logout(){
      localStorage.removeItem('session')
      this.$router.push({name: 'login'})
    }
  }
};
</script>

<style scoped>
  header {background-color: #edf0f7;margin-bottom: 10px;}
  .container {width: 1200px;height: 40px;margin: 0 auto;display: flex;justify-content: space-between;align-items: center;}
  .title-1 {color: red;font-family: inglobal-Bold, serif;font-size: 1.25rem;}
  .title-2 {color: #212121;font-family: inglobal-Bold, serif;font-size: 1.25rem;}
  .block{display: flex;align-items: center;}
  .fa-arrow-right-from-bracket{font-size: 20px;margin-left: 50px;cursor: pointer;color: #3b3b3b;}
  .fa-arrow-right-from-bracket:hover{color: #212121;}
  .block-user{display:flex;align-items: center;}
  .block-user span{margin-left: 10px;font-size: 13px;}
  .fa-user{font-size: 14px;color: #000000;}
</style>