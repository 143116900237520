<template>
  <div id="login">
    <div class="container-login">
      <form class="login-form" @submit.prevent="signIn">
        <span class="login-form-title">
          Authorization
        </span>
        <div class="wrap-input">
          <input v-model="form.email" type="email" placeholder="Email" class="input" />
          <span class="focus-input"></span>
          <span class="symbol-input">
            <i class="fa fa-envelope mr-5"></i>
          </span>
        </div>
        <div class="wrap-input">
          <input v-model="form.password" type="password" placeholder="Password" class="input" />
          <span class="focus-input"></span>
          <span class="symbol-input">
            <i class="fa fa-lock"></i>
          </span>
        </div>

        <div v-if="formErrors.length > 0" class="alert-container">
          <div v-for="(error, errorIndex) in formErrors" :key="errorIndex">
            <i class="fa fa-circle"></i>
            {{ error }}
          </div>
        </div>

        <div class="container-btn">
          <button type="submit" class="login-btn">
            Login
          </button>
        </div>
        <div class="container-btn">
          <router-link class="password-btn" :to="{name: 'password'}">
            Forgot your password?
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: "login",
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      formErrors: []
    }
  },
  methods: {
    async signIn() {
        try{
          const session = await this.$api.auth.signInSession(this.form)
          localStorage.setItem('session', JSON.stringify(session.data))
          const user = (await this.$api.user.getUser()).data
          this.$store.dispatch('setUser', user)
          this.$router.push({name: 'roadmap'})
        }catch(error){
          if(error.response.status === 401){
            this.formErrors = ['Неправильный логин и/или пароль']
          }
        }
    },
  }
}
</script>

<style>
html {background-color: #e2e6ee}
</style>

<style scoped>
#login{width:100%;margin:0 auto; height: 100%;background-color: #e2e6ee;}
.container-login{height:100%;width:100%;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;padding:0;background-repeat:no-repeat;background-size:cover;background-position:center;}
.login-form{background-color:#212121;border-radius: 20px;padding: 20px; border: 1px solid rgb(0, 0, 0);width:450px;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;justify-content:space-between;flex-wrap:wrap}
.login-form-title{margin-bottom:30px;margin-top:20px;font-family:Montserrat-ExtraBold, sans-serif;font-size:24px;color:#ffffff;line-height:1.2;text-align:center;width:100%;display:block}
.wrap-input{position:relative;width:100%;z-index:1;margin-bottom:15px;}
.input{width: calc(100% - 83px); font-family:Montserrat-Bold, sans-serif;font-size:15px;line-height:1.2;color:#333;display:block;background:#fff;height:50px;border-radius:25px;padding:0 30px 0 53px;border:1px solid rgb(0,0,0,0.2)}
.focus-input{display:block;position:absolute;border-radius:25px;bottom:0;left:0;z-index:-1;width:100%;height:100%;box-shadow:0 0;color:rgba(0,91,234,.6)}
.input:focus+.focus-input{-webkit-animation:anim-shadow .5s ease-in-out forwards;animation:anim-shadow .5s ease-in-out forwards}@-webkit-keyframes anim-shadow{to{box-shadow:0 0 80px 30px;opacity:0}}@keyframes anim-shadow{to{box-shadow:0 0 80px 30px;opacity:0}}
.symbol-input{font-size:15px;color:#999;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;align-items:center;position:absolute;border-radius:25px;bottom:0;left:0;width:100%;height:100%;padding-left:25px;pointer-events:none;-webkit-transition:all .4s;-o-transition:all .4s;-moz-transition:all .4s;transition:all .4s}
.input:focus+.focus-input+.symbol-input{color:#00c6fb;padding-left:23px}
.container-btn{width:100%;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;flex-wrap:wrap;justify-content:center}
.login-btn{margin-top:10px;border:none;font-family:Montserrat-Bold, sans-serif;font-size:15px;line-height:1.5;color:#e0e0e0;width:100%;height:50px;border-radius:25px;background:#333;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;justify-content:center;align-items:center;padding:0 25px;-webkit-transition:all .4s;-o-transition:all .4s;-moz-transition:all .4s;transition:all .4s;position:relative;z-index:1}
.login-btn::before{content:"";display:block;position:absolute;z-index:-1;width:100%;height:100%;border-radius:25px;top:0;left:0;background:#005bea;background:-webkit-linear-gradient(to left ,#005bea,#00c6fb);background:-o-linear-gradient(to left,#005bea,#00c6fb);background:-moz-linear-gradient(to left,#005bea,#00c6fb);background:linear-gradient(to left,#005bea,#00c6fb);-webkit-transition:all .4s;-o-transition:all .4s;-moz-transition:all .4s;transition:all .4s;opacity:0}
.login-btn:hover{background:0 0;color:#fff;cursor: pointer;}
.login-btn:hover:before{opacity:1}
:active,:hover,:focus {outline:0;outline-offset: 0;}
.alert-container{color: #ffffff;width: 100%;margin-left: 20px;margin-bottom: 15px;font-size: 12px;display: inline-block;font-family:Montserrat-Regular, sans-serif;text-align: left;}
.fa-circle{font-size: 5px;vertical-align: middle;}
.password-btn{color: #d2d2d2;cursor: pointer;border: none;outline: none;background: none;font-family:Montserrat-Regular, sans-serif;margin-top: 20px;}
.password-btn:hover{color: #b0b0b0;}
</style>